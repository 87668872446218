import React, {useEffect, useState} from 'react';
import Axios from 'axios';
import WithDataLoading from './api/WithDataLoading';
import BuildCrossword from './BuildCrossword';
import RandomNumberInRange from './api/RandomNumberInRange';

const BoardElements = () => {

    const DataLoading = WithDataLoading(BuildCrossword);

    // Fetch data from API using the Axios library
    // Remember Axios needs to be installed : npm install axios
    const[crosswordData, setCrosswordData] = useState({
        loading: false,
        repos : null,
    });
   
    useEffect(() => {
        setCrosswordData({loading:true});
        //Axios.get('http://localhost:3001/getGurutzegramak')
        Axios.get('https://gurutzegramak.herokuapp.com/getGurutzegramak')
        .then(repos => {
            const size = repos.data.length;
            const chosenItemNum = RandomNumberInRange(0,size-1);
            const chosenCrossword = repos.data[chosenItemNum];
            setCrosswordData({loading:false , repos:chosenCrossword});
        })
        .catch(error => {
            console.log(error);
        });
    }, [setCrosswordData]);
  
    return(
        <React.Fragment>
            <BuildCrossword isLoading={crosswordData.loading} repos={crosswordData.repos} />
        </React.Fragment>
    );

}

export default BoardElements;




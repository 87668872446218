import update from 'immutability-helper'
import './dnd/dnd.css'
import React, { memo, useCallback, useState } from 'react'
import { WordLabel } from './dnd/WordLabel.js'
import { WordHome } from './dnd/WordHome.js'
import { ItemTypes } from './dnd/ItemTypes.js'

import './css/crossword_style.css';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import AcrossFlags from './boardElements/AcrossFlags';
import DownFlags from './boardElements/DownFlags';
import { height } from '@mui/system'

const BuildCrossword = memo(function BuildCrossword(props) {
	
  const [solutionBoard, setSolutionBoard] = useState('none')
  const [solutionButtonTxt, setSolutionButtonTxt] = useState('Erantzuna')
  const [solutionButtonColor, setSolutionButtonColor] = useState('tomato')

	const [wordHomes, setWordHomes] = useState([
    
    {text: '', accepts: [ItemTypes.A,ItemTypes.B,ItemTypes.C,ItemTypes.D,ItemTypes.E,ItemTypes.F,ItemTypes.G,ItemTypes.H,ItemTypes.I,ItemTypes.J,ItemTypes.K,ItemTypes.L,ItemTypes.M,ItemTypes.N,ItemTypes.O,ItemTypes.P,ItemTypes.Q,ItemTypes.R,ItemTypes.S,ItemTypes.T,ItemTypes.U,ItemTypes.V,ItemTypes.W,ItemTypes.X,ItemTypes.Y,ItemTypes.Z,], lastDroppedItem: null },
    {text: '', accepts: [ItemTypes.A,ItemTypes.B,ItemTypes.C,ItemTypes.D,ItemTypes.E,ItemTypes.F,ItemTypes.G,ItemTypes.H,ItemTypes.I,ItemTypes.J,ItemTypes.K,ItemTypes.L,ItemTypes.M,ItemTypes.N,ItemTypes.O,ItemTypes.P,ItemTypes.Q,ItemTypes.R,ItemTypes.S,ItemTypes.T,ItemTypes.U,ItemTypes.V,ItemTypes.W,ItemTypes.X,ItemTypes.Y,ItemTypes.Z,], lastDroppedItem: null },
    {text: '', accepts: [ItemTypes.A,ItemTypes.B,ItemTypes.C,ItemTypes.D,ItemTypes.E,ItemTypes.F,ItemTypes.G,ItemTypes.H,ItemTypes.I,ItemTypes.J,ItemTypes.K,ItemTypes.L,ItemTypes.M,ItemTypes.N,ItemTypes.O,ItemTypes.P,ItemTypes.Q,ItemTypes.R,ItemTypes.S,ItemTypes.T,ItemTypes.U,ItemTypes.V,ItemTypes.W,ItemTypes.X,ItemTypes.Y,ItemTypes.Z,], lastDroppedItem: null },
    {text: '', accepts: [ItemTypes.A,ItemTypes.B,ItemTypes.C,ItemTypes.D,ItemTypes.E,ItemTypes.F,ItemTypes.G,ItemTypes.H,ItemTypes.I,ItemTypes.J,ItemTypes.K,ItemTypes.L,ItemTypes.M,ItemTypes.N,ItemTypes.O,ItemTypes.P,ItemTypes.Q,ItemTypes.R,ItemTypes.S,ItemTypes.T,ItemTypes.U,ItemTypes.V,ItemTypes.W,ItemTypes.X,ItemTypes.Y,ItemTypes.Z,], lastDroppedItem: null },
    {text: '', accepts: [ItemTypes.A,ItemTypes.B,ItemTypes.C,ItemTypes.D,ItemTypes.E,ItemTypes.F,ItemTypes.G,ItemTypes.H,ItemTypes.I,ItemTypes.J,ItemTypes.K,ItemTypes.L,ItemTypes.M,ItemTypes.N,ItemTypes.O,ItemTypes.P,ItemTypes.Q,ItemTypes.R,ItemTypes.S,ItemTypes.T,ItemTypes.U,ItemTypes.V,ItemTypes.W,ItemTypes.X,ItemTypes.Y,ItemTypes.Z,], lastDroppedItem: null },
    {text: '', accepts: [ItemTypes.A,ItemTypes.B,ItemTypes.C,ItemTypes.D,ItemTypes.E,ItemTypes.F,ItemTypes.G,ItemTypes.H,ItemTypes.I,ItemTypes.J,ItemTypes.K,ItemTypes.L,ItemTypes.M,ItemTypes.N,ItemTypes.O,ItemTypes.P,ItemTypes.Q,ItemTypes.R,ItemTypes.S,ItemTypes.T,ItemTypes.U,ItemTypes.V,ItemTypes.W,ItemTypes.X,ItemTypes.Y,ItemTypes.Z,], lastDroppedItem: null },
    {text: '', accepts: [ItemTypes.A,ItemTypes.B,ItemTypes.C,ItemTypes.D,ItemTypes.E,ItemTypes.F,ItemTypes.G,ItemTypes.H,ItemTypes.I,ItemTypes.J,ItemTypes.K,ItemTypes.L,ItemTypes.M,ItemTypes.N,ItemTypes.O,ItemTypes.P,ItemTypes.Q,ItemTypes.R,ItemTypes.S,ItemTypes.T,ItemTypes.U,ItemTypes.V,ItemTypes.W,ItemTypes.X,ItemTypes.Y,ItemTypes.Z,], lastDroppedItem: null },
    {text: '', accepts: [ItemTypes.A,ItemTypes.B,ItemTypes.C,ItemTypes.D,ItemTypes.E,ItemTypes.F,ItemTypes.G,ItemTypes.H,ItemTypes.I,ItemTypes.J,ItemTypes.K,ItemTypes.L,ItemTypes.M,ItemTypes.N,ItemTypes.O,ItemTypes.P,ItemTypes.Q,ItemTypes.R,ItemTypes.S,ItemTypes.T,ItemTypes.U,ItemTypes.V,ItemTypes.W,ItemTypes.X,ItemTypes.Y,ItemTypes.Z,], lastDroppedItem: null },
    {text: '', accepts: [ItemTypes.A,ItemTypes.B,ItemTypes.C,ItemTypes.D,ItemTypes.E,ItemTypes.F,ItemTypes.G,ItemTypes.H,ItemTypes.I,ItemTypes.J,ItemTypes.K,ItemTypes.L,ItemTypes.M,ItemTypes.N,ItemTypes.O,ItemTypes.P,ItemTypes.Q,ItemTypes.R,ItemTypes.S,ItemTypes.T,ItemTypes.U,ItemTypes.V,ItemTypes.W,ItemTypes.X,ItemTypes.Y,ItemTypes.Z,], lastDroppedItem: null },
    {text: '', accepts: [ItemTypes.A,ItemTypes.B,ItemTypes.C,ItemTypes.D,ItemTypes.E,ItemTypes.F,ItemTypes.G,ItemTypes.H,ItemTypes.I,ItemTypes.J,ItemTypes.K,ItemTypes.L,ItemTypes.M,ItemTypes.N,ItemTypes.O,ItemTypes.P,ItemTypes.Q,ItemTypes.R,ItemTypes.S,ItemTypes.T,ItemTypes.U,ItemTypes.V,ItemTypes.W,ItemTypes.X,ItemTypes.Y,ItemTypes.Z,], lastDroppedItem: null },
    {text: '', accepts: [ItemTypes.A,ItemTypes.B,ItemTypes.C,ItemTypes.D,ItemTypes.E,ItemTypes.F,ItemTypes.G,ItemTypes.H,ItemTypes.I,ItemTypes.J,ItemTypes.K,ItemTypes.L,ItemTypes.M,ItemTypes.N,ItemTypes.O,ItemTypes.P,ItemTypes.Q,ItemTypes.R,ItemTypes.S,ItemTypes.T,ItemTypes.U,ItemTypes.V,ItemTypes.W,ItemTypes.X,ItemTypes.Y,ItemTypes.Z,], lastDroppedItem: null },
    {text: '', accepts: [ItemTypes.A,ItemTypes.B,ItemTypes.C,ItemTypes.D,ItemTypes.E,ItemTypes.F,ItemTypes.G,ItemTypes.H,ItemTypes.I,ItemTypes.J,ItemTypes.K,ItemTypes.L,ItemTypes.M,ItemTypes.N,ItemTypes.O,ItemTypes.P,ItemTypes.Q,ItemTypes.R,ItemTypes.S,ItemTypes.T,ItemTypes.U,ItemTypes.V,ItemTypes.W,ItemTypes.X,ItemTypes.Y,ItemTypes.Z,], lastDroppedItem: null },
    {text: '', accepts: [ItemTypes.A,ItemTypes.B,ItemTypes.C,ItemTypes.D,ItemTypes.E,ItemTypes.F,ItemTypes.G,ItemTypes.H,ItemTypes.I,ItemTypes.J,ItemTypes.K,ItemTypes.L,ItemTypes.M,ItemTypes.N,ItemTypes.O,ItemTypes.P,ItemTypes.Q,ItemTypes.R,ItemTypes.S,ItemTypes.T,ItemTypes.U,ItemTypes.V,ItemTypes.W,ItemTypes.X,ItemTypes.Y,ItemTypes.Z,], lastDroppedItem: null },
    {text: '', accepts: [ItemTypes.A,ItemTypes.B,ItemTypes.C,ItemTypes.D,ItemTypes.E,ItemTypes.F,ItemTypes.G,ItemTypes.H,ItemTypes.I,ItemTypes.J,ItemTypes.K,ItemTypes.L,ItemTypes.M,ItemTypes.N,ItemTypes.O,ItemTypes.P,ItemTypes.Q,ItemTypes.R,ItemTypes.S,ItemTypes.T,ItemTypes.U,ItemTypes.V,ItemTypes.W,ItemTypes.X,ItemTypes.Y,ItemTypes.Z,], lastDroppedItem: null },
    {text: '', accepts: [ItemTypes.A,ItemTypes.B,ItemTypes.C,ItemTypes.D,ItemTypes.E,ItemTypes.F,ItemTypes.G,ItemTypes.H,ItemTypes.I,ItemTypes.J,ItemTypes.K,ItemTypes.L,ItemTypes.M,ItemTypes.N,ItemTypes.O,ItemTypes.P,ItemTypes.Q,ItemTypes.R,ItemTypes.S,ItemTypes.T,ItemTypes.U,ItemTypes.V,ItemTypes.W,ItemTypes.X,ItemTypes.Y,ItemTypes.Z,], lastDroppedItem: null },
    {text: '', accepts: [ItemTypes.A,ItemTypes.B,ItemTypes.C,ItemTypes.D,ItemTypes.E,ItemTypes.F,ItemTypes.G,ItemTypes.H,ItemTypes.I,ItemTypes.J,ItemTypes.K,ItemTypes.L,ItemTypes.M,ItemTypes.N,ItemTypes.O,ItemTypes.P,ItemTypes.Q,ItemTypes.R,ItemTypes.S,ItemTypes.T,ItemTypes.U,ItemTypes.V,ItemTypes.W,ItemTypes.X,ItemTypes.Y,ItemTypes.Z,], lastDroppedItem: null },
    {text: '', accepts: [ItemTypes.A,ItemTypes.B,ItemTypes.C,ItemTypes.D,ItemTypes.E,ItemTypes.F,ItemTypes.G,ItemTypes.H,ItemTypes.I,ItemTypes.J,ItemTypes.K,ItemTypes.L,ItemTypes.M,ItemTypes.N,ItemTypes.O,ItemTypes.P,ItemTypes.Q,ItemTypes.R,ItemTypes.S,ItemTypes.T,ItemTypes.U,ItemTypes.V,ItemTypes.W,ItemTypes.X,ItemTypes.Y,ItemTypes.Z,], lastDroppedItem: null },
    {text: '', accepts: [ItemTypes.A,ItemTypes.B,ItemTypes.C,ItemTypes.D,ItemTypes.E,ItemTypes.F,ItemTypes.G,ItemTypes.H,ItemTypes.I,ItemTypes.J,ItemTypes.K,ItemTypes.L,ItemTypes.M,ItemTypes.N,ItemTypes.O,ItemTypes.P,ItemTypes.Q,ItemTypes.R,ItemTypes.S,ItemTypes.T,ItemTypes.U,ItemTypes.V,ItemTypes.W,ItemTypes.X,ItemTypes.Y,ItemTypes.Z,], lastDroppedItem: null },
    {text: '', accepts: [ItemTypes.A,ItemTypes.B,ItemTypes.C,ItemTypes.D,ItemTypes.E,ItemTypes.F,ItemTypes.G,ItemTypes.H,ItemTypes.I,ItemTypes.J,ItemTypes.K,ItemTypes.L,ItemTypes.M,ItemTypes.N,ItemTypes.O,ItemTypes.P,ItemTypes.Q,ItemTypes.R,ItemTypes.S,ItemTypes.T,ItemTypes.U,ItemTypes.V,ItemTypes.W,ItemTypes.X,ItemTypes.Y,ItemTypes.Z,], lastDroppedItem: null },
    {text: '', accepts: [ItemTypes.A,ItemTypes.B,ItemTypes.C,ItemTypes.D,ItemTypes.E,ItemTypes.F,ItemTypes.G,ItemTypes.H,ItemTypes.I,ItemTypes.J,ItemTypes.K,ItemTypes.L,ItemTypes.M,ItemTypes.N,ItemTypes.O,ItemTypes.P,ItemTypes.Q,ItemTypes.R,ItemTypes.S,ItemTypes.T,ItemTypes.U,ItemTypes.V,ItemTypes.W,ItemTypes.X,ItemTypes.Y,ItemTypes.Z,], lastDroppedItem: null },
    {text: '', accepts: [ItemTypes.A,ItemTypes.B,ItemTypes.C,ItemTypes.D,ItemTypes.E,ItemTypes.F,ItemTypes.G,ItemTypes.H,ItemTypes.I,ItemTypes.J,ItemTypes.K,ItemTypes.L,ItemTypes.M,ItemTypes.N,ItemTypes.O,ItemTypes.P,ItemTypes.Q,ItemTypes.R,ItemTypes.S,ItemTypes.T,ItemTypes.U,ItemTypes.V,ItemTypes.W,ItemTypes.X,ItemTypes.Y,ItemTypes.Z,], lastDroppedItem: null },
    {text: '', accepts: [ItemTypes.A,ItemTypes.B,ItemTypes.C,ItemTypes.D,ItemTypes.E,ItemTypes.F,ItemTypes.G,ItemTypes.H,ItemTypes.I,ItemTypes.J,ItemTypes.K,ItemTypes.L,ItemTypes.M,ItemTypes.N,ItemTypes.O,ItemTypes.P,ItemTypes.Q,ItemTypes.R,ItemTypes.S,ItemTypes.T,ItemTypes.U,ItemTypes.V,ItemTypes.W,ItemTypes.X,ItemTypes.Y,ItemTypes.Z,], lastDroppedItem: null },
    {text: '', accepts: [ItemTypes.A,ItemTypes.B,ItemTypes.C,ItemTypes.D,ItemTypes.E,ItemTypes.F,ItemTypes.G,ItemTypes.H,ItemTypes.I,ItemTypes.J,ItemTypes.K,ItemTypes.L,ItemTypes.M,ItemTypes.N,ItemTypes.O,ItemTypes.P,ItemTypes.Q,ItemTypes.R,ItemTypes.S,ItemTypes.T,ItemTypes.U,ItemTypes.V,ItemTypes.W,ItemTypes.X,ItemTypes.Y,ItemTypes.Z,], lastDroppedItem: null },
    {text: '', accepts: [ItemTypes.A,ItemTypes.B,ItemTypes.C,ItemTypes.D,ItemTypes.E,ItemTypes.F,ItemTypes.G,ItemTypes.H,ItemTypes.I,ItemTypes.J,ItemTypes.K,ItemTypes.L,ItemTypes.M,ItemTypes.N,ItemTypes.O,ItemTypes.P,ItemTypes.Q,ItemTypes.R,ItemTypes.S,ItemTypes.T,ItemTypes.U,ItemTypes.V,ItemTypes.W,ItemTypes.X,ItemTypes.Y,ItemTypes.Z,], lastDroppedItem: null },
    {text: '', accepts: [ItemTypes.A,ItemTypes.B,ItemTypes.C,ItemTypes.D,ItemTypes.E,ItemTypes.F,ItemTypes.G,ItemTypes.H,ItemTypes.I,ItemTypes.J,ItemTypes.K,ItemTypes.L,ItemTypes.M,ItemTypes.N,ItemTypes.O,ItemTypes.P,ItemTypes.Q,ItemTypes.R,ItemTypes.S,ItemTypes.T,ItemTypes.U,ItemTypes.V,ItemTypes.W,ItemTypes.X,ItemTypes.Y,ItemTypes.Z,], lastDroppedItem: null },
  ])

  const [wordLabels] = useState([
    { name: 'A', type: ItemTypes.A },
    { name: 'B', type: ItemTypes.B },
    { name: 'C', type: ItemTypes.C },
    { name: 'D', type: ItemTypes.D },
    { name: 'E', type: ItemTypes.E },
    { name: 'F', type: ItemTypes.F },
    { name: 'G', type: ItemTypes.G },
    { name: 'H', type: ItemTypes.H },
    { name: 'I', type: ItemTypes.I },
    { name: 'J', type: ItemTypes.J },
    { name: 'K', type: ItemTypes.K },
    { name: 'L', type: ItemTypes.L },
    { name: 'M', type: ItemTypes.M },
    { name: 'N', type: ItemTypes.N },
    { name: 'O', type: ItemTypes.O },
    { name: 'P', type: ItemTypes.P },
    { name: 'Q', type: ItemTypes.Q },
    { name: 'R', type: ItemTypes.R },
    { name: 'S', type: ItemTypes.S },
    { name: 'T', type: ItemTypes.T },
    { name: 'U', type: ItemTypes.U },
    { name: 'V', type: ItemTypes.V },
    { name: 'W', type: ItemTypes.W },
    { name: 'X', type: ItemTypes.X },
    { name: 'Y', type: ItemTypes.Y },
    { name: 'Z', type: ItemTypes.Z },
  ])
  
  const [droppedWordLabelNames, setDroppedWordLabelNames] = useState([])
  
  const handleDrop = useCallback(
    (index, item) => {
        const { name, type} = item
        setDroppedWordLabelNames(name)
        setWordHomes(
          update(wordHomes, {
            [index]: {
              lastDroppedItem: {
                $set: name
              },
            },
          }),
        )
      },
      [droppedWordLabelNames, wordHomes],
    )

    if(!props.repos || props.repos.length === 0) return <p style={{ color:'#fff', textAlign:'center' }}>API data loading now ...</p>
  
    const rows = props.repos.rows;
    const cols = props.repos.cols;
    const crosswordString = props.repos.finalBoard;
    const across = props.repos.across;
    const down = props.repos.down;
 
    // cellWidth should be equal to width in dnd.css .wordHome class
    const cellWidth = 50;
    const cellHeight = cellWidth;
    const BLACK = '*';
    const WHITE = ' ';
    const boardWidth = cellWidth * cols + 10;
    const boardHeight = cellHeight * rows + 10;
    
   
    const acrossFlags = AcrossFlags(rows, cols, BLACK, crosswordString);
    const downFlags = DownFlags(rows, cols, BLACK, crosswordString);
  
    let crosswordArray = [];
    let crosswordBoardArray = [];
    let startingCrosswordBoard = [];
    var indexH = 1;
    var indexV = 1;

    for(var i = 0; i < crosswordString.length; i++){
        // Crossword Solution array
        if(crosswordString[i] === BLACK){
            crosswordArray.push([BLACK,'black']);
        } else {
            crosswordArray.push([crosswordString[i],'transparent']);
        }
        
        // Crossword Board array
        if(crosswordString[i] === BLACK){
            crosswordBoardArray.push([BLACK,'black']);
        } else {
            crosswordBoardArray.push([WHITE,'transparent']);
        }

        // Starting Crossword Board
        if(crosswordString[i] === BLACK){
            startingCrosswordBoard.push([BLACK,'black',' ',' ']);
        } else {
            if(acrossFlags.includes(i) && downFlags.includes(i)){
                startingCrosswordBoard.push([crosswordString[i],'transparent',indexH + '→' , indexV + '↓']);
                indexH++;
                indexV++;
            
            } else if(acrossFlags.includes(i)){
                startingCrosswordBoard.push([crosswordString[i],'transparent',indexH + '→', ' ']);
                indexH++;
            
            } else if(downFlags.includes(i)){
                startingCrosswordBoard.push([crosswordString[i],'transparent', ' ' , indexV + '↓']);
                indexV++;
            
            } else {
                startingCrosswordBoard.push([crosswordString[i],'transparent', ' ' , ' ']); 
            }
        }
    }

    
    
    function handleCrosswordSolutionOnClick(e){
      e.preventDefault();
      if(solutionBoard==='none'){
        setSolutionBoard('block');  
        setSolutionButtonTxt('Erantzuna Itxi');
        setSolutionButtonColor('lightgreen');
      } else {
        setSolutionBoard('none');  
        setSolutionButtonTxt('Erantzuna');
        setSolutionButtonColor('tomato');
      }
        
    }
 
    return(
            <Grid className="crosswordElementsGrid">
              <div className="buildCrosswordContainer" style={{ width:boardWidth, margin:'0 auto'}}>
                <div className="buildCrossword" style={{ 
                  width: `${boardWidth}px`,
                  height: `${boardHeight}px`,
                  margin:'0 auto',    
                 }}> 
                    {startingCrosswordBoard.map((boardItem, index) => (
                        <div key={index} className="crosswordCells" style={{
                          width: `${cellWidth}px`,
                          height: `${cellHeight}px`,
                            backgroundColor: boardItem[1],
                            }}>
                            <div className="acrossFlags">
                                {boardItem[2]}
                            </div>
                            <div className="downFlags">
                                {boardItem[3]}
                            </div>    
                        </div>  
                    ))}
                </div>
                <div className="copyright">kobikibu©</div>
                
		        <div className="wordHomeWrapper" style={{  zIndex:'5000' , width: `${boardWidth}px`,
            height: `${boardHeight}px`,margin:'0 auto'}}>
		          {wordHomes.map(({ accepts, lastDroppedItem, text }, index) => (
		            <WordHome 
		              accept={accepts}
		              lastDroppedItem={lastDroppedItem}
		              text={text}
		              onDrop={(item) => handleDrop(index, item)}
		              key={index}
		            /> 
		          ))}
		        </div>
		        <div className="wordLabelWrapper">
		          {wordLabels.map(({ name, type }, index) => (
		            <WordLabel
		              name={name}
		              type={type}
		              key={index}
		            />
		          ))}
		        </div>
              <div className="wordDefinitions"><b>Horizontala :</b>
                    <p>{across}</p>
                </div>
                <div className="wordDefinitions"><b>Bertikala :</b>
                    <p>{down}</p>
                </div>
                <div className="crosswordSolution" >
                  <button onClick={handleCrosswordSolutionOnClick} style={{ backgroundColor:`${solutionButtonColor}` }}><b>{solutionButtonTxt}</b></button>
                  <div style={{ display:`${solutionBoard}`}}>

                  <div className="buildCrossword" style={{  
                  width: `${boardWidth}px`,height: `${boardHeight}px`, margin:'0 auto', marginTop:'1em'     
                 }}> 
                  {startingCrosswordBoard.map((boardItem, index) => (
                        <div key={index} className="crosswordCells" style={{
                          width: `${cellWidth}px`,
                          height: `${cellHeight}px`,
                            backgroundColor: boardItem[1],
                            textAlign:'center',
                            lineHeight:`${cellHeight}px`,
                            verticalAlign:'middle',
                            }}>
                            <b>{boardItem[0]}</b>
                        </div>  
                    ))}
                </div>
                <div className="copyright">kobikibu©</div>
                  </div>
                </div>
              </div>
            </Grid>
    )
})

export default BuildCrossword
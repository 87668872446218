export default function AcrossFlags(rows, cols, BLACK, crosswordString) {

    const accrossFlags = [];
  
    let word = null;
    let fhIndex = 0;
    let horizontalIndex = 1;
    for (var i = 0; i < rows; i++){
        for (var j = 0; j < cols; j++){
            let fhCharacter = crosswordString[fhIndex];
            if (fhCharacter !== BLACK){
                if(word === null){
                    word = fhCharacter;
                    accrossFlags.push(fhIndex);
                    horizontalIndex++;
                } else {
                    word = word + fhCharacter;
                }
            }
                
            if (fhCharacter === BLACK && word !== null){
                word = null;
            }
            fhIndex++;
        }
        // Row change => new word
        word = null;
    }

    return accrossFlags;
}


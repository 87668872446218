export default function DownFlags (rows, cols, BLACK, crosswordString) {

    const downFlags = [];
    let fvIndex = 0;
    let verticalIndex = 1;
    for (var i = 0; i < rows; i++){
        for (var j = 0; j < cols; j++){
            let fvCharacter = crosswordString[fvIndex];
            if (i === 0 && fvCharacter !== BLACK){
                downFlags.push(fvIndex);
                verticalIndex++;
            }
            // All cases except first line
            let aboveIndex = fvIndex - cols;
            if (aboveIndex >= 0) {
                let aboveCharacter = crosswordString[aboveIndex];
                if (fvCharacter !== BLACK && aboveCharacter !== null && aboveCharacter === BLACK ){
                    downFlags.push(fvIndex);
                    verticalIndex++;
                }
            }   
            fvIndex++;   
        }
    }
    return downFlags;
}
